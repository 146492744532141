@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
  /* --color-surface: Main background (use for page or card background) */
  --color-surface: #fff;

  /* --color-on-surface: Text/icons on the surface. */
  --color-on-surface: theme("colors.slate.500");

  /* --color-surface-variant: Secondary background for containers or inputs. */
  --color-surface-variant: #f8f9fa;

  /* --color-on-surface-variant: Text/icons on secondary surfaces.. */
  --color-on-surface-variant: #3c3c43;

  /* --color-outline: Default borders for interactive elements. */
  --color-outline: theme("colors.gray.500");

  /* --color-outline-variant: Subtle borders or dividers. */
  --color-outline-variant: theme("colors.gray.300");
}

.dark {
  --color-surface: #0e1217;
  --color-on-surface: #fffff5;
  --color-surface-variant: #1c1f26;
  --color-on-surface-variant: #a8b3cf;
  --color-outline: theme("colors.gray.400");
  --color-outline-variant: theme("colors.gray.600");
}

.app-input {
  @apply border-outline-variant bg-surface-variant text-on-surface-variant;
  /* Focus state */
  :focus {
    @apply ring-2 ring-blue-500;
  }
  /* Disabled state */
  :disabled {
    @apply cursor-not-allowed opacity-50;
  }
}

.app-card {
  @apply rounded-md border border-outline-variant bg-surface-variant text-on-surface-variant transition-all;

  /* Hover state */
  @apply hover:shadow-lg hover:dark:shadow-white/10;
  /* Focus state */
  @apply focus:ring-2 focus:ring-blue-500;
}

@layer base {
  body {
    @apply bg-surface;
    @apply text-on-surface;
  }
}

@layer utilities {
  .overflow-hidden {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .perspective-1000 {
    perspective: 1000px;
  }

  .transform-style-3d {
    transform-style: preserve-3d;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Custom image styles */

.ProseMirror img {
  transition: filter 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &.ProseMirror-selectednode {
    outline: 3px solid #5abbf7;
    filter: brightness(90%);
  }
}

/* Custom TODO list checkboxes – shoutout to this awesome tutorial: https://moderncss.dev/pure-css-custom-checkbox-style/ */

ul[data-type="taskList"] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type="taskList"] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type="taskList"] li > label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 5px;
  border: 2px solid black;
  margin-right: 0.3rem;
  display: grid;
  place-content: center;

  &:hover {
    background-color: #f8f9fa;
  }

  &:active {
    background-color: #e9ecef;
  }

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em;
    transform-origin: center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
}

ul[data-type="taskList"] li[data-checked="true"] > div > p {
  color: #a8a29e;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

/* Custom ant */
.ant-form-item .ant-form-item-label label {
  @apply !text-on-surface;
}
